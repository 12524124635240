<template>
  <div class="article container">
    <div class="crumb py-1">
      <a-breadcrumb separator=">">
        <template v-for="(item, index) in breadList">
          <a-breadcrumb-item :key="index">
            <router-link :to="item.path">{{ item.name }}</router-link>
          </a-breadcrumb-item>
        </template>
      </a-breadcrumb>
    </div>
    <div class="article-main">
      <div class="article-menu">
        <div class="menu-list pt-2">
          <h3 class="title">资质标准</h3>
          <div class="list mt-2">
            <template v-for="(item, index) in criterion">
              <router-link :key="index" :to="'/article?id=' + item.id" class="item pl-4 pr-3" :class="{ active: item.id == id }">
                {{ item.title }}
              </router-link>
            </template>
          </div>
        </div>
        <div class="news-list py-2 mt-2">
          <h3 class="title">最新资讯</h3>
          <div class="list mt-2">
            <template v-for="(item, index) in newArtcle">
              <router-link :key="index" :to="'/article?id=' + item.id" class="item pl-4 pr-3" :class="{ active: item.id == id }">
                {{ item.title }}
              </router-link>
            </template>
          </div>
        </div>
      </div>
      <div class="article-content">
        <div class="box py-3 px-4">
          <h1 class="title">{{ article.title }}</h1>
          <div class="info mt-2 mb-3">
            <div class="number">
              <p>
                发布于 <span>{{ article.create_time | getDate }}</span>
              </p>
              <p>
                浏览量 <span>{{ article.view_num | addNumber }}</span
                >人
              </p>
            </div>
            <div class="tag">
              <p># {{ article.category_name }}</p>
            </div>
          </div>
          <div class="content px-1" v-html="article.content" :class="{ isMore: !isMore }"></div>
          <p class="showMore" v-show="!isMore" @click="showMore">
            展开查看全部&nbsp;&gt;
          </p>
          <a-button class="btn" type="primary" @click="$toConnectLink"> <img src="@/assets/image/decoration/075.png" alt="" />行业信息在线咨询 </a-button>
          <div class="adv">
            <img src="@/assets/image/about/bottom.png" alt="" />
          </div>
        </div>
        <div class="prev-next py-2">
          <p class="prev" :class="{ hadval: prev }" @click="toPrev">上一篇：{{ prev ? prev.title : "无" }}</p>
          <p class="next" :class="{ hadval: next }" @click="toNext">下一篇：{{ next ? next.title : "无" }}</p>
        </div>
        <div class="m-article-menu">
          <div class="menu-list pt-2">
            <h3 class="title">资质标准</h3>
            <div class="list mt-2">
              <template v-for="(item, index) in criterion">
                <router-link :key="index" :to="'/article?id=' + item.id" class="item pl-4 pr-3" :class="{ active: item.id == id }">
                  {{ item.title }}
                </router-link>
              </template>
            </div>
          </div>
          <div class="news-list py-2 mt-2">
            <h3 class="title">最新资讯</h3>
            <div class="list mt-2">
              <template v-for="(item, index) in newArtcle">
                <router-link :key="index" :to="'/article?id=' + item.id" class="item pl-4 pr-3" :class="{ active: item.id == id }">
                  {{ item.title }}
                </router-link>
              </template>
            </div>
          </div>
        </div>
        <div class="about py-2">
          <div class="top">
            <h3 class="pl-1">相关热门资讯</h3>
            <router-link to="/policy" class="more pr-4">more+</router-link>
          </div>
          <div class="list">
            <template v-for="(item, index) in aboutArtcle">
              <router-link :key="index" :to="'/article?id=' + item.id" class="item mt-2 px-6" :class="{ active: id == item.id }">
                {{ item.title }}
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMore: false,
      id: this.$route.query.id,
      article: "",
      breadList: [],
      flag: this.$route.query.flag || 0,
      except: this.$route.query.except || 0,
      categoryId: this.$route.query.categoryId || 0,
      professionId: this.$route.query.professionId || 0,
      prev: {
        id: 0,
        title: "",
      },
      next: {
        id: 0,
        title: "",
      },
      aboutArtcle: [],
      newArtcle: [],
      criterion: [],
    };
  },
  filters: {
    getDate(val) {
      if (val) {
        let arr = val.split(" ");
        return arr[0];
      }
      return;
    },
    addNumber(val) {
      return val + Math.floor(Math.random() * 1000) + 100;
    },
  },
  methods: {
    showMore() {
      this.isMore = true;
    },
    toNext() {
      if (this.next) {
        this.$router.push({
          name: "article",
          query: {
            id: this.next.id,
            flag: this.flag,
            except: this.except,
            categoryId: this.categoryId,
            professionId: this.professionId,
          },
        });
      }
    },
    toPrev() {
      if (this.prev) {
        this.$router.push({
          name: "article",
          query: {
            id: this.prev.id,
            flag: this.flag,
            except: this.except,
            categoryId: this.categoryId,
            professionId: this.professionId,
          },
        });
      }
    },
    getArticleDetail() {
      this.$api
        .getArticleDetail({
          id: this.id,
          flag: this.flag,
          except: this.except,
          categoryId: this.categoryId,
          professionId: this.professionId,
        })
        .then((res) => {
          this.article = res.data.info;
          this.prev = res.data.prev;
          this.next = res.data.next;
          document.title = this.article.title;
          document.querySelector('meta[name="keywords"]').setAttribute("content", this.article.keywords);
          document.querySelector('meta[name="description"]').setAttribute("content", this.article.guide);
          // this.next.id = res.data.next.id;
          // this.next.title = res.data.next.title;
          // this.prev.id = res.data.prev.id;
          // this.prev.title = res.data.prev.title;
          this.flag = res.data.info.flag;
          this.getAbout();
        });
    },
    getNew() {
      this.$api
        .getArticleList({
          flag: "info",
          limit: 6,
        })
        .then((res) => {
          this.newArtcle = res.data.list;
        });
    },
    getCriterion() {
      this.$api
        .getArticleList({
          flag: "criterion",
          limit: 6,
        })
        .then((res) => {
          this.criterion = res.data.list;
        });
    },
    getAbout() {
      this.$api
        .getArticleList({
          flag: this.flag,
          limit: 8,
        })
        .then((res) => {
          this.aboutArtcle = res.data.list;
        });
    },
  },
  components: {},
  created() {
    this.breadList = this.$route.meta.breadList;
    this.breadList = this.breadList.filter((item) => item.name != undefined);
    this.getArticleDetail();
    this.getNew();
    this.getCriterion();
  },
  watch: {
    $route(to, from) {
      this.id = to.query.id;
      this.getArticleDetail();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/article.scss";
</style>
